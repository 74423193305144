import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faOctagonCheck } from "@fortawesome/pro-duotone-svg-icons";

const Services = (props) => {
    const { sectionOne, sectionTwo, sectionThree } = props;
    return (
        <section className="overview-area ptb-100">
            <div className="container">
                {/* Left Image Style */}
                <div className="overview-box">
                    <div className="overview-image">
                        <div className="image">
                            <img src={sectionOne.img} alt="features" style={{maxWidth: '100%'}}/>
                        </div>
                    </div>

                    <div className="overview-content">
                        <div className="content right-content">
                            <span className="sub-title">{sectionOne.subTitle}</span>
                            <h2>{sectionOne.title}</h2>
                            <p>{sectionOne.description}</p>

                            <div className="features-text">
                              <h3>
                                 <FontAwesomeIcon icon={faCheck} style={{width: '20px', height: '20px' }}/>
                                 {sectionOne.bulletOneTitle}
                              </h3>
                                <p>{sectionOne.bulletOneDetails}</p>
                            </div>

                            <div className="features-text">
                                <h3>
                                  <FontAwesomeIcon icon={faCheck} style={{width: '20px', height: '20px' }}/>
                                  {sectionOne.bulletTwoTitle}
                                </h3>
                                <p>{sectionOne.bulletTwoDetails}</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Left Image Style */}

                {/* Right Image Style */}
                <div className="overview-box">
                    <div className="overview-content">
                        <div className="content">
                            <span className="sub-title">{sectionTwo.subTitle}</span>
                            <h2>{sectionTwo.title}</h2>
                            <p>{sectionTwo.description}</p>

                            <div className="features-text">
                                <h3>
                                  <FontAwesomeIcon icon={faCheck} style={{width: '20px', height: '20px' }}/>
                                  {sectionTwo.bulletOneTitle}
                                </h3>
                            </div>

                            <div className="features-text">
                                <h3>
                                  <FontAwesomeIcon icon={faCheck} style={{width: '20px', height: '20px' }}/>
                                  {sectionTwo.bulletTwoTitle}
                                </h3>
                            </div>

                            <div className="features-text">
                                <h3>
                                  <FontAwesomeIcon icon={faCheck} style={{width: '20px', height: '20px' }}/>
                                  {sectionTwo.bulletThreeTitle}
                                </h3>
                            </div>
                        </div>
                    </div>

                    <div className="overview-image">
                        <div className="image">
                            <img src={sectionTwo.img} alt="features" style={{maxWidth: '100%'}}/>
                        </div>
                    </div>
                </div>
                {/* End Right Image Style */}

                {/* Left Image Style */}
                <div className="overview-box">
                    <div className="overview-image">
                        <div className="image">
                            <img src={sectionThree.img} alt="features" style={{maxWidth: '100%'}}/>
                        </div>
                    </div>

                    <div className="overview-content">
                        <div className="content right-content">
                            <span className="sub-title">{sectionThree.subTitle}</span>
                            <h2>{sectionThree.title}</h2>
                            <p>{sectionThree.description}</p>

                            <ul className="features-list">
                                <li>
                                  <span>
                                    <FontAwesomeIcon icon={faOctagonCheck} className="flaticon-right" style={{width: '23px', height: '23px' }}/>
                                     Infrastructure
                                  </span>
                                </li>
                                <li>
                                  <span>
                                    <FontAwesomeIcon icon={faOctagonCheck} className="flaticon-right" style={{width: '23px', height: '23px' }}/>
                                     Site Reliability Engineering
                                  </span>
                                </li>
                                <li>
                                  <span>
                                    <FontAwesomeIcon icon={faOctagonCheck} className="flaticon-right" style={{width: '23px', height: '23px' }}/>
                                     Quality Engineering
                                  </span>
                                </li>
                                <li>
                                  <span>
                                    <FontAwesomeIcon icon={faOctagonCheck} className="flaticon-right" style={{width: '23px', height: '23px' }}/>
                                     Quality Assurance
                                  </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/* End Left Image Style */}
            </div>
        </section>
    )
}

export default Services
