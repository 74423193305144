// banner
import bannerImg3 from '../../assets/images/main-banner/astrolotos-isolated-with-logo.svg'

// services
import service1 from '../../assets/images/isometric-radial-graphs-stacked.svg'
import service2 from '../../assets/images/rocket-empty-state.svg'
import service3 from '../../assets/images/isometric-apple-devices.svg'


export const banner = {
  bannerImg: bannerImg3,
  gradient: "radial-gradient( circle farthest-corner at 6.6% 12%,  rgba(64,0,126,1) 20.8%, rgba(0,255,160,1) 100.2% )",
  title: 'Engineering Leader for High Growth Tech Companies',
  subTitle: 'Bryan Lokey',
  description: 'I build high-performance products, champion the DevOps transformation, and make jam',
  buttonTitle: 'Contact Me',
  buttonLink: '/contact',
};

export const aboutMe = {
  sectionOne: {
    title: 'Solving problems, building teams',
    subTitle: 'What I Like',
    description: 'I like to solve big problems and help scale those solutions across all of engineering. I do this by focusing on a few key concepts.',
    bulletOneTitle: 'Building a Foundation for Growth',
    bulletOneDetails: 'We have to build a foundation for growth because what worked today will not work tomorrow at a fast-growing company.',
    bulletTwoTitle: 'Provide a Vision of the Future',
    bulletTwoDetails: 'You must paint a clear picture of the desired end state before you can ask an engineering org to make big changes. I love to paint that picture as well as lead regular feedback sessions to confirm our direction.',
    img: service1,
  },
  sectionTwo: {
    title: 'Culture and technology transformations',
    subTitle: 'What I Do',
    description: 'I have carved out a niche as a person to call when your company is looking to move from the go to market phase to IPO scale and beyond.  I love the challenges of hockeysticking growth in both technology and people.  To make it easier I like to:',
    bulletOneTitle: 'Empower DevOps Transformations',
    bulletTwoTitle: 'Champion Observability',
    bulletThreeTitle: 'Create Self-Service',
    img: service2,
  },
  sectionThree: {
    title: 'Engineering Leadership at Kandji',
    subTitle: 'Where I Work',
    description: 'I work as a Senior Director of Engineering at Kandji. Kandji is a device management solution for high-growth businesses that run Apple devices. I lead the following teams:',
    img: service3,
    team1: 'Infrastructure',
    team2: 'Quality Engineering',
    team3: 'Site Reliability Engineering',
    team4: 'Quality Assurance',
  },
};

export const funFacts = {
  facts: [
    {
      title: '20 Years',
      subTitle: 'In the Industry',
    },
    {
      title: '100+',
      subTitle: 'Services Supported',
    },
    {
      title: '34',
      subTitle: 'Releases Per Week',
    },
    {
      title: '3',
      subTitle: 'DevOps Transformations',
    },
  ],
};

export const blogSection = {
  title: 'From the Blog',
  subTitle: 'There are many opinions on the internet. Here are some of mine.',
};
