import React from 'react'
import {Link} from 'gatsby'
import { author } from '../../data/page-data/authorData';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/pro-duotone-svg-icons";

const buildBlogList = articles => (
    articles.map(article => (
        <div className="col-lg-4 col-md-6">
          <div className="single-blog-post">
              <div className="post-image">
                  <Link to={article.node.frontmatter.slug}>
                      <img src={article.node.frontmatter.thumbnail.publicURL} alt="blog" />
                  </Link>
              </div>

              <div className="post-content">
                  <ul className="post-meta d-flex justify-content-between align-items-center">
                      <li>
                          <div className="post-author d-flex align-items-center">
                              <img src={author.thumbnail} className="rounded-circle" alt="blog" />
                              <span>{author.name}</span>
                          </div>
                      </li>
                      <li>
                      <FontAwesomeIcon icon={faCalendar} style={{width: '15px', height: '15px', marginRight: '5px' }}/>
                       {article.node.frontmatter.date}
                      </li>
                  </ul>
                  <h3>
                      <Link to={article.node.frontmatter.slug}>
                        {article.node.frontmatter.title}
                      </Link>
                  </h3>
              </div>
          </div>
      </div>
    ))
);

const OurBlog = (props) => {
    const { articles, title, subTitle } = props;
    return (
        <section className="blog-area pt-100 pb-70 bg-fafafa home">
            <div className="container">
                <div className="section-title">
                    <h2>{title}</h2>
                    <p>{subTitle}</p>
                </div>

                <div className="row align-items-center">
                    {buildBlogList(articles)}
                </div>
            </div>
        </section>
    )
}

export default OurBlog
