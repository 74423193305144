import React from "react"
import Layout from "../components/App/Layout"
import SEO from '../components/App/SEO'
import NavbarDynamic from "../components/App/NavbarDynamic"
import FooterMinimal from "../components/App/FooterMinimal"
import MainBanner from "../components/Home/MainBanner"
import Services from "../components/Home/Services"
import FunFacts from "../components/Home/FunFacts"
import OurBlog from "../components/Home/BlogPost"
import { banner, blogSection, aboutMe, funFacts } from '../data/page-data/homeData';
import { graphql } from "gatsby"

const headerInfo = {
  title: 'Bryan Lokey | Engineering Leader for High Growth Tech Companies',
  description: 'I build high-performance products, champion the DevOps transformation, and make jam',
  keywords: 'engineering leadership,devpps,sre,cloud engineering',
  canonical: 'https://bryanlokey.com',
  ogImage: banner.bannerImg,
};

const Index = ({
    data, // this prop will be injected by the GraphQL query below.
  }) => {
    const { allMarkdownRemark } = data // data.markdownRemark holds post data
    const articles = allMarkdownRemark.edges.slice(0, 3);
    return (
        <div>
          <SEO {...headerInfo}/>
          <Layout>
            <NavbarDynamic />
            <MainBanner {...banner} />
            <Services {...aboutMe} />
            <FunFacts {...funFacts} />
            <OurBlog {...blogSection} articles={articles} />
            <FooterMinimal />
          </Layout>
        </div>
    );
}

export default Index


export const pageQuery = graphql`
query {
  allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
    edges {
      node {
        id
        excerpt(pruneLength: 250)
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          slug
          title
          thumbnail  {
            publicURL
          }
          image  {
            publicURL
          }
          description
          tags
        }
      }
    }
  }
}`
