import React from 'react'

const buildFacts = facts => (
    facts.map(fact => (
        <div className="col-lg-3 col-sm-6 col-12">
            <div className="single-funfacts-item">
                <h3>{fact.title}</h3>
                <p>{fact.subTitle}</p>
            </div>
        </div>
    ))
);

const FunFacts = (props) => {
    const { facts } = props;
    return (
        <section className="funfacts-area bg-image pt-100 pb-70">
            <div className="container">
                <div className="row">
                    {buildFacts(facts)}
                </div>
            </div>
        </section>
    )
}

export default FunFacts
