import React from 'react'
import {Link} from 'gatsby'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronsRight } from "@fortawesome/pro-duotone-svg-icons";


const MainBanner = (props) => {
    const { bannerImg, title, subTitle, description, buttonTitle, buttonLink, gradient } = props;
    return (
        <div className="digital-agency-banner" style={{backgroundImage: gradient}}>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="banner-wrapper-content">
                            <span className="sub-title">{subTitle}</span>
                                <h1>{title}</h1>
                                <p>{description}</p>
                                <div className="btn-box">
                                    <Link to={buttonLink} className="default-btn">
                                        <FontAwesomeIcon icon={faChevronsRight} style={{width: '20px', height: '20px' }} />
                                        {buttonTitle}
                                        <span></span>
                                    </Link>
                                </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="banner-wrapper-image">
                            <img src={bannerImg} alt="banner" style={{maxHeight: "500px"}}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MainBanner
